#root {
  height: 100%;
}
html,
.h-100,
body {
  height: 100%;
  font-family: "josefin Sans";
}
$RED_COLOR: #fd4436;
$LOGO_COLOR: #01366e;
.main {
  height: 100%;
  position: relative;
  overflow: auto;
  font-family: "josefin Sans";

  &__header {
    height: 60px;
    background: #f0f9ff;
    position: fixed;
    overflow: hidden;
    z-index: 999;
    width: 100%;
    top: 0;
    /* Blur Effect */
    background-color: rgba(255, 255, 255, 0.64);
    backdrop-filter: blur(16.133333206176758px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--logo {
      display: flex;
      column-gap: 5px;
      align-items: center;
      margin-left: 10px;
      &--image {
        height: 50px;
        width: 50px;
      }
      h4 {
        font-size: 25px;
        color: $RED_COLOR;
      }
    }
    &--login {
      button {
        background-color: $RED_COLOR;
        border-style: none;
        color: white;
        margin-right: 10px;
        padding: 8px 10px;
        border-radius: 6px;
        font-weight: 700;
      }
    }
  }
  &__body {
    height: calc(85% - 60px);
    display: flex;
    &--left {
      height: 100%;
      background: #fff5f5;
      width: 50%;
      &--homepage {
        display: block;
        margin: auto;
        width: 100%;
        height: 100%;
      }
    }
    &--right {
      @extend .main__body--left;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      &--block {
        &--images {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          img {
            height: 120px;
            width: 120px;
          }
          h2 {
            color: $RED_COLOR;
            font-size: 80px;
          }
        }
        &--text {
          color: $LOGO_COLOR;
          font-size: 22px;
        }
      }
    }
  }
  &__second-image {
    background-color: $LOGO_COLOR;
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 10px;
    &--left {
      width: calc(45% - 10px);
      img {
        width: 80%;
      }
    }
    &--details {
      width: calc(55% - 10px);
      b {
        color: white;
        font-size: 40px;
        text-align: justify;
        line-height: 1.5;
      }
      span {
        display: block;
        margin-top: 10px;
        color: #f0f9ff;
        font-size: 25px;
        line-height: 1.5;
        text-align: justify;
      }
      img {
        margin-top: 20px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__third-image {
    display: flex;

    &--left {
      width: calc(45% - 20px);
      display: block;
      margin: auto;
      padding-left: 40px;
      padding-top: 25px;

      b {
        font-size: 40px;
        color: $RED_COLOR;
        line-height: 1.5;
      }
      &--block {
        span {
          display: block;
          margin-top: 10px;
          line-height: 1.5;
          font-size: 25px;
          color: $LOGO_COLOR;
        }
        img {
          width: 60%;
        }
      }
      &--image {
        margin: auto;
        display: block;
      }
    }
    &--right {
      width: calc(55% - 20px);
      background-color: #f0f9ff;
      img {
        display: block;
        margin: auto;
        width: 65%;
      }
    }
  }
  &__fourth-image {
    height: 100%;
    width: 100%;
    background-color: $LOGO_COLOR;
    background-image: url("./images/DesktopandMobile.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
  }
  &__address-block {
    display: flex;
    font-size: 19px;
    color: $LOGO_COLOR;
    height: fit-content;
    padding-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    column-gap: 10px;
    &--grid {
      display: flex;
      width: 20%;
      column-gap: 5px;

      img {
        width: 30px;
        height: 30px;
      }
      span {
        line-height: 1.2;
      }
      a {
        display: block;
        line-height: 1.2;
        letter-spacing: 0.01em;
        font-size: 20px;
        color: $LOGO_COLOR;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &--flex {
      display: flex;
      a {
        display: flex;
      }
    }
    &--logo {
      width: calc(20% - 40px);
      display: flex;
      align-items: center;
      img {
        width: 250px;
      }
    }
  }
  &__footer {
    background-color: $RED_COLOR;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    color: #f0f9ff;
    font-size: 16px;
    font-weight: 700;
    span {
      color: #f0f9ff;
      font-size: 16px;
      font-weight: 700;
      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .main {
    &__body {
      display: block;
      height: 80%;

      &--left {
        width: 100%;
        height: 55%;
        &--homepage {
          height: 100%;
          width: 100%;
        }
      }
      &--right {
        width: calc(100% - 20px);
        padding: 10px;
        height: 40%;

        &--block {
          &--images {
            margin-top: 20px;
            img {
              width: 80px;
            }
            h2 {
              font-size: 40px;
            }
          }
          &--text {
            font-size: 18px;
          }
        }
      }
    }
    &__second-image {
      display: block;
      height: fit-content;
      padding: 10px;
      &--left {
        width: calc(100% - 10px);
      }

      img {
        height: 50%;
        width: 85%;
      }
      &--details {
        width: calc(100% - 10px);

        img {
          width: 80%;
        }
        b {
          font-size: 20px;
        }
        span {
          font-size: 16px;
        }
      }
    }
    &__third-image {
      display: block;
      padding-left: 0px;
      &--left {
        width: calc(100% - 20px);
        padding-left: 20px;
        margin-top: 40px;
        b {
          font-size: 20px;
        }
        &--block {
          span {
            font-size: 14px;
          }
        }
        &--image {
          height: 60%;
          width: 60%;
        }
      }
      &--right {
        width: 100%;
        height: 60%;
        img {
          height: 80%;
          width: 80%;
          display: block;
          margin: auto;
        }
      }
    }
    &__fourth-image {
      background-image: url("./images/TabDesktopandMobile.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 70%;
      width: 100%;
    }
    &__address-block {
      display: block;
      padding-left: 10px;
      font-size: 16px;
      &--flex {
        width: 100%;
      }
      &--grid {
        width: 100%;
        margin-top: 10px;
        a {
          font-size: 16px;
        }
      }
      &--logo{
        width:calc( 100% - 40px);
        justify-content: center;
        img{
          width:80%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main {
    &__fourth-image {
      height: 60%;
      width: 100%;
      background-color: $LOGO_COLOR;
      background-image: url("./images/TabDesktopandMobile.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
    }
    &__body {
      display: block;
      height: 60%;

      &--left {
        width: 100%;
        height: 50%;
        &--homepage {
          height: 100%;
          width: 100%;
        }
      }
      &--right {
        width: 100%;
      }
      &--right {
        width: 100%;
      }
    }
    &__second-image {
      display: block;
      height: 100%;
      padding: 10px 20px;
      &--left {
        width: calc(100% - 20px);
      }
      img {
        height: 40%;
        width: 85%;
      }
      &--details {
        width: calc(100% - 20px);
        b {
          font-size: 25px;
        }
        span {
          font-size: 18px;
        }

        img {
          width: 60%;
        }
      }
    }
    &__third-image {
      display: block;
      margin: auto;
      &--left {
        width: calc(100% - 20px);
        padding-left: 20px;
        &--block {
          margin-top: 20px;
        }
      }
      &--right {
        width: 100%;
        img {
          width: 71%;
        }
      }
    }
    &__address-block {
      display: block;
      padding-left: 20px;
      font-size: 20px;
      &--flex {
        width: 100%;
      }
      &--grid {
        width: 100%;
        margin-top: 10px;
        a {
          font-size: 20px;
        }
      }
      &--logo{
        width: 100%;
        justify-content: center;
        img{
          width:80%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main {
    &__body {
      display: block;
      height: 85%;

      &--left {
        width: 100%;
        height: 60%;
        &--homepage {
          height: 100%;
          width: 100%;
        }
      }
      &--right {
        width: 100%;
        height: 45%;
        img {
          width: 80px;
        }
        h2 {
          font-size: 32px;
        }
      }
    }
    &__second-image {
      display: block;
      padding: 10px 20px;
      &--left {
        width: calc(100% - 20px);
        height: 45%;
      }
      img {
        display: block;
        margin: auto;
        width: 100%;
        height: 100%;
      }
      &--details {
        width: calc(100% - 20px);
        img {
          width: 60%;
        }
      }
    }
    &__third-image {
      display: block;
      width: 100%;
      padding: 0px !important;
      &--left {
        width: calc(100% - 20px);
        padding-left: 20px;
        margin-top: 20px;
      }
      &--right {
        width: 100%;
      }
    }

    &__fourth-image {
      height: 80%;
      width: 100%;
      background-color: $LOGO_COLOR;
      background-image: url("./images/TabDesktopandMobile.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 90%;
    }
    &__address-block {
      display: block;
      padding-left: 20px;
      font-size: 20px;
      &--flex {
        width: 100%;
      }
      &--grid {
        width: 100%;
        margin-top: 10px;
        a {
          font-size: 20px;
        }
      }
      &--logo{
        width: 100%;
        justify-content: center;
        img{
          width:80%;
        }
      }
    }
  }
}

@media (max-width: 280px) and (max-height: 653px) {
  .main {
    &__body {
      display: block;
      height: 80%;

      &--left {
        width: 100%;
        height: 70%;
        &--homepage {
          height: 100%;
          width: 100%;
        }
      }
      &--right {
        width: 100%;
        height: 20%;
        &--block {
          &--images {
            margin-top: 20px;
            img {
              width: 50px;
            }
          }
          &--text {
            font-size: 18px;
          }
        }
      }
    }
    &__second-image {
      display: block;
      height: 100%;
      img {
        height: 50%;
        width: 100%;
      }
      &--details {
        img {
          width: 60%;
        }
        b {
          font-size: 16px;
        }
        span {
          font-size: 14px;
        }
      }
    }
    &__third-image {
      display: block;
      &--left {
        width: 100%;
        b {
          font-size: 16px;
        }
        &--block {
          span {
            font-size: 14px;
          }
        }
        &--image {
          height: 60%;
          width: 60%;
        }
      }
      &--right {
        width: 100%;
        img {
          height: 80%;
          width: 80%;
          display: block;
          margin: auto;
        }
      }
    }
    &__fourth-image {
      background-image: url("./images/TabDesktopandMobile.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      height: 70%;
      width: 100%;
    }
  }
}

.privacy-policy-points {
  list-style-type: disc; /* Bullet point style */
  padding-left: 20px !important;
  margin-top: 20px;
}

.privacy-policy-list {
  list-style-type: none; /* Bullet point style */
  padding-left: 10px !important;
  margin-top: 20px;
  line-height: 1.2;
}
.privacy-policy-contents {
  list-style-type: none; /* Bullet point style */
  padding-left: 10px !important;
  margin-top: 20px;
  line-height: 1.2;
}
li {
  margin-top: 10px;
  line-height: 1.2;
}
.privacy-policy {
  height: 100%;
  position: relative;
  h1 {
    margin-top: 70px;
  }
  ul {
    margin-bottom: 0px !important;
  }
  .back-button {
    position: sticky;
    bottom: 10px;
    overflow: hidden;
    margin-left: 20px;
    padding: 6px 12px;
    background-color: orange;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    border-style: none;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}
.privacy-policy-details {
  margin: 10px 40px 70px 20px;
}
